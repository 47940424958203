<template>
    <v-container id="conf_settings_firma" class="w-container" style="padding-top:59px">

        <v-row>

            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

                <div class="mainTitle" style="
                background-color: white!important;
                color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color:#000082">{{ titolo }}</div>

            </v-col>


        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>

            <v-row>


                <v-col cols="12" md="12">

                    <ion-item id="itemTipo"
                        style="min-width:190px!important;transform: translateX(-6px);margin-top:-24px;margin-bottom:18px">

                        <ion-label style="font-size:12px;color:gray!important;opacity:1;cursor:pointer"
                            position="floating">Tipo</ion-label>

                        <ion-select id="tipo" name="tipo" class='tipo' cancel-Text="Chiudi" done-Text=""
                            :value="firma.tipo" interface="action-sheet" :disabled="valueReadonly">

                            <ion-select-option v-for="item in tipi" :key="item.id" :value='item.id'> {{ item.name }}
                            </ion-select-option>

                        </ion-select>
                    </ion-item>

                </v-col>
            </v-row>

            <!-- <v-row v-if="viewText">


                <v-col cols="12" md="12">

                    <v-textarea v-model="firma.testo" id="testo" label="Testo"></v-textarea>

                </v-col>


            </v-row> -->

            <v-row class="divFirma" style="display:none">


                <v-col cols="12" md="12">

                    <label style="margin-left:10px">Firma</label>
                    <VueSignaturePad width="480px" height="100px" ref="signaturePad" class="contSignature" />

                </v-col>


            </v-row>

            <v-row v-if="viewSign && totSignatures > 0">

                <v-col cols="12" md="12" xs="12">

                    <div style="display:flex;padding-left:10px">
                        <div>
                            <ion-checkbox id="firma" name="firma" slot="start" class="checkDefault" value="firma"
                                :checked="isDefault" v-model="firma.is_default" :disabled="valueReadonly">
                            </ion-checkbox>

                        </div>
                        <div style="padding-left: 10px;">
                            <ion-label style="font-size:1.2em">Default</ion-label>
                        </div>
                    </div>
                </v-col>


            </v-row>


            <v-row v-if="viewImage">

                <v-col cols="12" sm="12" md="12">
                    <template>

                        <span class="files">
                            <label style="margin-left:11px;font-size:11px">Immagine</label>
                            <input type="file" id="input-file-logo" name="logo" class="dropify" data-max-width="6610"
                                data-max-height="6200" />
                        </span>

                    </template>
                </v-col>


            </v-row>

            <v-row v-if="viewImage && totImages > 0">

                <v-col cols="12" md="12" xs="12">

                    <div style="display:flex;padding-left:10px">
                        <div>
                            <ion-checkbox id="firma" name="firma" slot="start" class="checkDefault" value="firma"
                                :checked="isDefault" v-model="firma.is_default" :disabled="valueReadonly">
                            </ion-checkbox>

                        </div>
                        <div style="padding-left: 10px;">
                            <ion-label style="font-size:1.2em">Default</ion-label>
                        </div>
                    </div>
                </v-col>


            </v-row>



        </v-form>








    </v-container>
</template>

<script>

import apisettings from "../utils/settings/apisettings";
import VueSignaturePad from 'vue-signature-pad';
import router from ".././router";

import {
    bus
} from "../main";

import Vue from "vue";
import VModal from 'vue-js-modal';


import apifirme from "../utils/firme/apifirme";


Vue.use(VueSignaturePad);



export default ({
    mounted() {

        this.$root.$children[0].checkLogout();
        
        try {
            this.$refs.signaturePad.undoSignature();
            this.$refs.signaturePad.openSignaturePad();
            this.$refs.signaturePad.clearSignature();

        } catch (error) {
            console.log(error);
        }



        window.dispatchEvent(new Event('resize'));


        console.log("PARAMS: ", this.$route.params.id);

        /*eslint-disable no-undef*/

        Vue.use(VModal, {
            dialog: true
        });

        var pointerVue = this;

        this.setupButtons(false);

        setTimeout(() => {
            this.syncConfInvioMail();

            this.syncDefaultCheckbox();

        }, 100);


        if (this.$route.params.id == 0) {

            this.titolo = "Inserisci Oggetto";

        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Oggetto";


            setTimeout(() => {

                this.syncSign();

            }, 100);



        }



        setTimeout(() => {

            this.$refs.signaturePad.undoSignature();
            this.$refs.signaturePad.openSignaturePad();
            this.$refs.signaturePad.clearSignature();


            window.dispatchEvent(new Event('resize'));





        }, 400);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));


            var canvas = document.getElementsByTagName("canvas")[0];

            var date = new Date();

            var month = date.getMonth() + 1;
            if (month.toString().length == 1) month = "0" + month.toString();

            let formatted_date = date.getDate() + "-" + month + "-" + date.getFullYear()

            var ctx = canvas.getContext("2d");
            ctx.font = "bold 12px Arial";
            ctx.fillText(formatted_date + " Per accettazione", 5, 10);

        }, 1000);



        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    //this.saveData();

                    this.validate();

                    break;

                case "btn_undo":

                    this.undo();

                    break;

                case "btn_back":

                    this.$refs.signaturePad.undoSignature();
                    this.$refs.signaturePad.openSignaturePad();
                    this.$refs.signaturePad.clearSignature();


                    window.dispatchEvent(new Event('resize'));


                    router.push({
                        path: "/firme"
                    });

                    break;



                default:
                    break;
            }
        });

        $(document).ready(function () {

            $(".tipo").on("ionChange", function (opt) {
                console.log(opt);

                console.log("VAL: ", $(this).val());


                if ($(this).val() == "Firma") {

                    pointerVue.viewSign = true;
                    pointerVue.viewImage = false;
                    pointerVue.viewText = false;

                    $(".divFirma").show();

                    pointerVue.$refs.signaturePad.openSignaturePad();
                    pointerVue.$refs.signaturePad.clearSignature();

                    setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 300);

                    pointerVue.setupButtons(true);

                }

                if ($(this).val() == "Immagine") {

                    pointerVue.viewImage = true;
                    pointerVue.viewSign = false;
                    pointerVue.viewText = false;

                    $(".divFirma").hide();

                    setTimeout(() => {

                        pointerVue.formatDropify();

                    }, 100);

                    pointerVue.setupButtons(false);

                }


            });


        });



        setTimeout(() => {

            pointerVue.sheetSignature = true;

        }, 600);




    },


    computed: {

        mailMinutesFromTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_form_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_form_time);

                console.log("MAIL MINUTES TIME FROM: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.impostazioni_invio_mail.mail_minutes_to_time != null) {

                strTime = new String(this.impostazioni_invio_mail.mail_minutes_to_time);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeFromUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_from_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_from_use);

                console.log("MAIL MINUTES TIME FROM USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        },

        timeToUse() {

            var strTime = "";

            if (this.impostazioni_invio_mail.time_to_use != null) {

                strTime = new String(this.impostazioni_invio_mail.time_to_use);

                console.log("MAIL MINUTES TIME TO USE: " + strTime);

                strTime = strTime.substring(0, 5);

            }

            return strTime;

        }

    },


    data: () => ({

        hasFile: false,

        valueReadonly: false,

        currentFile: null,

        tipi: [{ id: "Firma", name: "Firma" }, { id: "Immagine", name: "Immagine" }],

        isDefault: false,

        sheetSignature: false,

        totSignatures: 0,
        totImages: 0,

        viewText: false,
        viewImage: false,
        viewSign: false,

        coordXFirma: null,
        coordYFirma: null,

        firma: {},

        fieldDis: false,

        showRow: true,
        showRow2: true,

        //  currentFile: null,
        currDipendenteObj: {},
        dipendenti: [],
        currAzienda: "",
        titolo: "Firma",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        impostazioni: { is_notify_enable: "N" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        idTipologiaRules: [
            (v) => !!v || "Tipologia richiesta",
        ],

        importoRules: [
            (v) => !!v || "Importo richiesto",
        ],

        dataScadenzaRules: [
            (v) => !!v || "Data scadenza richiesta",
        ],

        minutiTotRules: [
            (v) => !!v || "Minuti richiesti",
        ],

        select: null,
        checkbox: false,



    }),


    methods: {

        getUrlFolder: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },


        formatDropify: function () {

            var that = this;

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

                that.hasFile = false;

            });

        },


        undo() {
            this.$refs.signaturePad.undoSignature();
        },

        saveData: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");

            console.log(v_token);

            that.firma.tipo = $("#tipo").val();

            if (that.firma.tipo == "Testo") {

                that.firma.testo = $("#testo").val();

            }

            if (that.firma.tipo == "Firma") {

                const { isEmpty, data } = that.$refs.signaturePad.saveSignature();

                console.log(isEmpty);

                that.firma.content_signature = data;

                var checkDefault = 1;

                if (that.totSignatures > 0) {

                    checkDefault = 0;

                    if ($(".checkDefault").prop("checked")) {
                        checkDefault = 1;
                    }

                }

                that.firma.is_default = checkDefault;


            }


            console.log("FIRMA: ", that.firma);

            if (that.firma.tipo == "Immagine") {


                this.currentFile = $('#input-file-logo')[0].files;

                var checkDefault2 = 1;

                if (that.totImages > 0) {

                    checkDefault2 = 0;

                    if ($(".checkDefault").prop("checked")) {
                        checkDefault2 = 1;
                    }

                }

                that.firma.is_default = checkDefault2;

            }



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apifirme.updateFirma(
                v_token,
                that.firma,
                that.$route.params.id,
                that.totSignatures,
                this.currentFile,
                that.totImages
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateFirma", res);

                that.$swal({
                    icon: "success",
                    text: "Dati salvati correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons(false);

                    router.push({
                        path: "/firme"
                    });

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );




        },

        chkChange: function () {

            if (!$("#chkMinutes").is(":checked")) {

                this.showRow = true;

            } else {

                this.impostazioni_invio_mail.mail_minutes_tot = null;

                this.impostazioni_invio_mail.mail_minutes_form_time = null;

                this.impostazioni_invio_mail.mail_minutes_to_time = null;

                this.impostazioni_invio_mail.mail_minutes_monday = "N";

                this.impostazioni_invio_mail.mail_minutes_tuesday = "N";

                this.impostazioni_invio_mail.mail_minutes_wednesday = "N";

                this.impostazioni_invio_mail.mail_minutes_thursday = "N";

                this.impostazioni_invio_mail.mail_minutes_friday = "N";

                this.impostazioni_invio_mail.mail_minutes_saturday = "N";

                this.impostazioni_invio_mail.mail_minutes_sunday = "N";

                this.showRow = false;

            }

        },

        chkChangeExpired: function () {

            if (!$("#chkMinutesExpiring").is(":checked")) {

                this.showRow2 = true;

            } else {

                this.impostazioni_invio_mail.mail_att_expiring_minute = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_from = null;

                this.impostazioni_invio_mail.mail_att_expiring_time_to = null;

                this.impostazioni_invio_mail.mail_att_expiring_monday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_tuesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_wednesday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_thursday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_friday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_saturday = "N";

                this.impostazioni_invio_mail.mail_att_expiring_sunday = "N";

                this.showRow2 = false;

            }

        },

        setupButtons: async function (show_btn_clean) {

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_docsign.png",
                    //class: "inverted",
                    title: "Salva",
                    width: 30

                });

                if (show_btn_clean) {

                    pulsantis.push({
                        text: "Pulisci",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_undo",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/undo2.png",
                       // class: "inverted",
                        title: "Pulisci",
                        width: 25

                    });

                }

                pulsantis.push({
                    text: "Pulisci",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_back",
                    disabled: false,
                    title: "Indietro",
                    image: "https://app.emtool.eu/public/_lib/img/indietro_docsign.png",
                    //class: "inverted",
                    width: 30
                });

           

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {

            var errore = "";

            var that = this;

            // console.log("FIRMA: ", that.firma);

            if ($("#tipo").val() == "") {
                errore = "Specificare il tipo";
            }

            if (errore == "") {

                if ($("#tipo").val() == "Testo") {

                    if ($("#testo").val() == "") {
                        errore = "Specificare il testo";
                    }

                }

            }

            if (errore == "") {

                if ($("#tipo").val() == "Firma") {

                    const { isEmpty, data } = that.$refs.signaturePad.saveSignature();

                    console.log(data);

                    if (isEmpty) {

                        errore = "Specificare la firma";

                    }


                }

            }

            if (errore == "") {

                if ($("#tipo").val() == "Immagine") {

                    if ($('#input-file-logo')[0].files.length == 0) {


                        if (!that.hasFile) {

                            errore = "Specificare l'immagine";

                        }

                    }

                }

            }




            if (errore.length == 0) {

                if ($("#tipo").val() == "Immagine") {


                    if ($('#input-file-logo')[0].files.length > 0) {

                        var myfile = $('#input-file-logo')[0].files[0];

                        var lfile = myfile.name.split(".");

                        console.log("MYFILE: ", lfile[lfile.length - 1]);

                        var extension = lfile[lfile.length - 1]

                        console.log("EXT: ", extension);

                        if ((extension.toString().toUpperCase() != "JPG") && (extension.toString().toUpperCase() != "JPEG") && (extension.toString().toUpperCase() != "GIF") && (extension.toString().toUpperCase() != "PNG") && (extension.toString().toUpperCase() != "BMP")) {

                            errore = "Accettati solo formati JPG, JPEG, GIF, BMP, PNG";

                        }


                    }


                }


            }






            if (errore == "") {


                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        syncDefaultCheckbox: async function () {

            var that = this;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apifirme.getDefaultCheckbox(
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDefaultCheckbox", res);

                that.totSignatures = res.data.TotSignatures;
                that.totImages = res.data.TotImages;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },


        syncSign: async function () {

            var that = this;

            //var signaturePad = this.$refs.signaturePad;

            var v_token = window.$cookies.get("token");



            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apifirme.getSign(
                v_token,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSign", res);

                that.firma = res.data.signature;



                console.log("DEFAULT: ", res.data.signature.is_default);

                if (res.data.signature.is_default == 1) {

                    console.log("OK");

                    that.valueReadonly = true;

                    setTimeout(() => {

                        $(".checkDefault").prop("checked", true);

                    }, 200);



                }


                if (res.data.signature.content_signature != null) {

                    //console.log("CONTENT SIGN: ", res.data.signature.content_signature);

                    var content = res.data.signature.content_signature;

                    console.log("CONTENT SIGN: ", content);

                    setTimeout(() => {
                        $("canvas").hide();
                        $("canvas").show();


                        setTimeout(() => {

                            var canv = document.getElementsByTagName("canvas")[0];
                            var ctx = canv.getContext("2d");

                            var myImage = new Image();
                            //myImage.width = 200;

                            //console.log("myImage: ", myImage);

                            myImage.src = content;
                            //ctx.drawImage(myImage, 0, 0, 960, 200);

                            ctx.drawImage(myImage, 0, 0, 480, 100);

                            // window.dispatchEvent(new Event('resize'));


                            //that.$refs.signaturePad.fromData(content);

                        }, 500);

                    }, 100);


                }



                if (res.data.signature.file_immagine != null) {

                    var tipo_immagine = res.data.signature.tipo_immagine;

                    console.log("TIPO IMMAGINE: ", tipo_immagine);


                    that.hasFile = true;

                    var txt_image = "";

                    var nome_file = res.data.signature.file_immagine;

                    var elements = nome_file.split(".");

                    var extension = elements[elements.length - 1];

                    console.log("EXT: ", extension);




                    if (tipo_immagine == "documento") {

                        txt_image = extension;
                    }

                    if (tipo_immagine == "immagine") {

                        txt_image = this.getUrlFolder("public/2sign/" + res.data.signature.file_immagine);
                    }



                    console.log("ALLEGATO: ", txt_image);

                    setTimeout(() => {

                        if (tipo_immagine == "immagine") {

                            $(".dropify-render img").attr("src", txt_image);
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }

                        if (tipo_immagine == "documento") {

                            $('.dropify-render').append("<i class='dropify-font-file'></i>");
                            $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

                            // dropify-font-file
                            $(".dropify-wrapper").addClass("has-preview");
                            $(".dropify-preview").show("slow");

                        }

                        // if (isImage) {
                        //     $(".dropify-render img").attr("src", txt_image);
                        //     $(".dropify-wrapper").addClass("has-preview");
                        //     $(".dropify-preview").show("slow");

                        // }
                        // else {

                        //     $('.dropify-render').append("<i class='dropify-font-file'></i>");
                        //     $('.dropify-render').append("<span class='dropify-extension'>" + txt_image + "</span>");

                        //     // dropify-font-file
                        //     $(".dropify-wrapper").addClass("has-preview");
                        //     $(".dropify-preview").show("slow");

                        // }



                    }, 200);


                }







            }).catch(err => {
                //that.$root.$children[0].showProgress = false;
                console.log(err);
                console.log(response);

                //var msg = err.response.data.Error;

                // that.$swal({
                //     icon: "error",
                //     text: msg,
                //     showConfirmButton: false,
                //     timer: 8000
                // });
                // console.log("ERrori", "Non è stato possibile salvare i dati");
                // console.log("response", response);

            }

            );



        },


        syncConfInvioMail: async function () {

            // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apisettings.getSettings(
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getSettings", res);

                try {

                    that.impostazioni = res.data.settings;

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }



    }

})
</script>

<style>
#conf_settings_firma .v-label {
    font-size: 1.2em !important;
}

#conf_settings_firma .v-input {
    font-size: 1em !important;
    padding: 10px !important;
}



.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.centered-input input {
    text-align: center
}

.cl_combo1 {
    margin-left: -41px !important
}

@media screen and (max-width: 768px) {

    .cl_combo1 {
        margin-left: -29px !important
    }

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}


.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid hsla(0, 0%, 50.2%, 0.33);
    padding-bottom: 10px;
    padding-top: 10px;
}

.buttonsPdf {
    margin-bottom: 150px;
    padding-top: 10px;
}

.buttonsPdf button {
    margin-right: 6px;
    margin-left: 6px;
}

.v-bottom-sheet.v-dialog {
    align-self: flex-start !important;
    /* top:80px!important; */
    margin-top: 69px;
}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}

.dropify-wrapper {
    height: 236px !important;
    width: 100% !important;
    margin-bottom: 20px;
    margin-left: 0px;
}

.files label {
    margin-left: 0px !important;
}
</style>
