<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
// import {
//     bus
// } from "../main";
import router from ".././router";
// import $ from 'jquery';
//import Login from './login.vue'

export default {



    mounted: async function () {

        console.log(this.$root.$children);
        console.log("RELOAD PARAMS: ", this.$route.params.id);

        setTimeout(() => {

            router.push({
                path: "/" + this.$route.params.id
            });


        }, 200);



    },


};
</script>
