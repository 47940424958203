<template>
  <div>
    <v-carousel
      class="mainCarousel"
      height="100vh"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <v-carousel-item class="itemCar" v-for="(slide, i) in items" :key="i">
        <!--   <div :id="'image' + i">
             
          </div>  -->
        <div
          class="divImageOk"
          :id="'image' + i"
          :style="{ 'background-image': 'url(' + slide.src + ')' }"
        ></div>
      </v-carousel-item>
    </v-carousel>

    <v-bottom-navigation
      class="bottomReturn"
      v-model="value"
      background-color="white"
      dark
      shift
    >
      <router-link class="linkBottom" tag="a" :to="'/'"> LOGIN</router-link>
    </v-bottom-navigation>
  </div>
</template>

<script>
//import apidoor from "../utils/door/apidoor";
import apidoor from "../utils/door/apidoor";
export default {
  methods: {


        getUrlDomainSimple: function (nome_metodo) {
      let result = "";
 /*eslint-disable no-undef*/
      if ($(location).attr("hostname") == "localhost") {
        result = $(location).attr("protocol") + "//emtool.local/";
      }

      if ($(location).attr("hostname") != "localhost") {
        result =
          $(location).attr("protocol") +
          "//" +
          $(location).attr("hostname") +
          "/";
      }

      return result + "" + nome_metodo;
    },



    initSlide: async function () {
      //Start

      var appdir = "2sign";
      var apptable = "docsign_settings";
      var prePath = this.getUrlDomainSimple("public/" + appdir + "/slides/");
      var username = window.$cookies.get("username");
      var v_token = window.$cookies.get("token");
      var that = this;
      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apidoor
        .getSlides(username, v_token, apptable)
        .then((res) => {
          that.$root.$children[0].showProgress = false;
          console.log("res from initslides get", res.data);
          var arr = res.data.data;

          var newItems = [];

          try {
            arr.forEach(function (path) {
              console.log("ciao", path);

              newItems.push({ src: prePath + path });
            });

            that.items = newItems;
          } catch (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          that.$root.$children[0].showProgress = false;
          console.log(err);
          var msg = err.response.data.Error;

          that.$swal({
            icon: "error",
            text: msg,
            showConfirmButton: false,
            timer: 8000,
          });
          console.log("ERrori", "Non è stato possibile effettuare la chiamata");
          console.log("response", response);
        });
      //ENd
    },
  },
  data() {
    return {
      items: [
         
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  mounted: function () {
     /*eslint-disable no-undef*/
    this.initSlide();
  },
};
</script>

<style>
.bottomReturn {
  transform: none;
  background: white;
  display: flex;

  height: 52px;
  position: fixed;
  align-items: center;
}
.mainCarousel .v-carousel__controls {
  bottom: 44px;
}


 .mainCarousel .theme--dark.v-btn.v-btn--icon {
  color: #000000;
}


.linkBottom {
  font-weight: 600;
  text-decoration: none;

  bottom: 16px;
  text-align: right;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5) !important;
  align-items: center;
}

.fullScreen {
  min-height: 100vh;
}

div#image0,
div#image1,
div#image2,
div#image3 {
  min-height: 100vh;
  min-width: 100vw;

  max-width: 100px;

  background-size: contain !important;
  background-repeat: no-repeat;

  max-width: 100px;
  background-position: center;
}
div#image0 {
  background-image: url(/easyonoff/img/mobile_easy.jpg);
}
div#image1 {
  background-image: url(/easyonoff/img/mobile_easy_2.jpg);
}
</style>