import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIFirme {


    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }

    getUrlDomainApi(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + "api/" + nome_metodo;


    }

    async updateFirma(user, firma, id, n_sign, file, n_images) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("n_sign", n_sign);
        formData.append("n_images", n_images);

        if (file && (file != undefined)) {

            formData.append("file", file[0]);

        }


        for (var key in firma) {
            formData.append(key, firma[key]);
        }

        return axios.post(this.getUrlDomainApi("updSignaturesApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getDefaultCheckbox(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomainApi("getSignaturesTotNumApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getImageSignDefault(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomainApi("DocSignGetSignImageDefault"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async getSign(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomainApi("getSignatureApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async delSign(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomainApi("delSignatureApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async delSignMulti(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        return axios.post(this.getUrlDomainApi("DocSignDelMultiSignatureApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }





}

export default new APIFirme({
    url: "http://emtool.local/public/api/smartbook/"
})
