<template>
    <v-app id='app' ref="mainApp" class="my-application">

        <v-app-bar v-if="!hideToolbars" app color="primary" dark class="headerTop" style="z-index:322;">


            <div class="d-flex align-center topLeftButtons">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon id="no-background-hover" to="/dash" class='btnDash'>
                            <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/emt-logo-small.png"
                                transition="scale-transition" width="40" />
                        </v-btn>
                    </template>
                    <span>Home</span>
                </v-tooltip>


                <div class="zooms" style="display:none;">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon id="no-background-hover" class='btnZoomin'>
                                <v-img alt="Vuetify Logo" class="shrink mr-2" contain
                                    src="@/assets/icons8-zoom-avanti-48.png" transition="scale-transition" width="30" />
                            </v-btn>
                        </template>
                        <span>Zoom in</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon id="no-background-hover" class='btnZoomout'>
                                <v-img alt="Vuetify Logo" class="shrink mr-2" contain
                                    src="@/assets/icons8-zoom-indietro-48.png" transition="scale-transition"
                                    width="30" />
                            </v-btn>
                        </template>
                        <span>Zoom out</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon id="no-background-hover" class='btnHelp'>
                                <v-img alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/help.png"
                                    transition="scale-transition" width="30" />
                            </v-btn>
                        </template>
                        <span>Help</span>
                    </v-tooltip>



                </div>




            </div>

            <v-spacer></v-spacer>



            <div class="curDipOreTopLabel"> {{ curDipOreTopLabel }} </div>

            <v-btn @click="message" href="" text class='btnTopDocSign' v-if="totalMsg > 0">

                <v-badge top="true" color="white" :content="msgFormatted" overlap right dark>
                    <v-img alt="Profile" width="28" height="28" class="imgProfile " contain src="@/assets/mailover.png"
                        transition="scale-transition" />
                </v-badge>
            </v-btn>

            <v-btn v-for="item in buttonsInfoUser" :key="item.title" href="" text class="btnInfoUserCodSign"
                @click="openPopupInfoUser">


                <v-img :alt="fullNameUser" class="  " contain src="@/assets/profiloDocSign.png" max-width="32"
                    max-height="32" transition="scale-transition" :title="fullNameUser" />

            </v-btn>

        </v-app-bar>

        <v-main style="padding-bottoms:60px;">

            <router-view />

            <v-layout v-if="showProgress" class="progressBar" align-center justify-center column fill-height>
                <v-flex row align-center>
                    <v-progress-circular indeterminate :size="50" color="primary" class=""></v-progress-circular>
                </v-flex>
            </v-layout>

        </v-main>

        <template>
            <div v-if="prova" class="prova">Prova {{ prova }}</div>


            <v-bottom-navigation v-if="!hideToolbars" :background-color="'#a3a3a2'" style="z-index:3333;" dark fixed>


                <div class="wrap_buttons_footer">

                    <v-btn v-for="(item, i) in bnavbuttons" :key="i" :ref="'' + item.id" :disabled="item.disabled"
                        :id="item.id" @click="bottomIconsEvents($event)" class="v-btn--actives">

                        <v-icon v-if="!item.image" v-text="item.icon"></v-icon>


                        <img v-if="item.image && !item.width" :src="item.image" width="22" :id="item.id"
                            :class="item.class" :title="item.title" />

                        <img v-if="item.image && item.width" :src="item.image" :width="item.width" :id="item.id"
                            :class="item.class" :title="item.title" />

                    </v-btn>



                </div>


                <v-btn class="bottomBtnMenu" @click="openmenu">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </v-bottom-navigation>

            <aziende ref='mainAziende'></aziende>

            <menubottom ref="menubottom" v-if="renderMenu"> </menubottom>

        </template>

        <div class="contpopupinfouser" style="display:none">

            <template>
                <modal name="popupInfoUser" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Informazioni Utente

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" class="clRowInfoUser">

                            <div style="width: 80px;">Utente:</div>
                            <div style="font-weight: bold;">{{ fullNameUser }}</div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" class="clRowInfoUser">

                            <div style="width: 80px;">Ultimo Login:</div>
                            <div style="font-weight: bold;">{{ curDataOraLogin }}</div>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-info-user" contain
                                    src="@/assets/btn_cancel3_docsign.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Annulla" @click="btnCancelInfoUser" />

                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </v-app>
</template>

<script>

import aziende from './components/aziende';
import menubottom from './components/menubottom';
import {
    bus
} from './main'

import apiusers from "./utils/users/apiusers";


export default {

    mounted: function () {

        /*eslint-disable no-undef*/

        // var refs = this.$refs;
        //console.log ("Mounted app 1" , refs.btn_add_tms    );

        window.addEventListener('contextmenu', this.disableContextMenu);

        window.addEventListener('keydown', this.disableF12);


        var namesurname = window.$cookies.get("namesurname");

        this.curDipTop = namesurname;



        var token = window.$cookies.get("token");

        this.changeMenu();
        if (token !== null) {
            this.syncMonth();
            this.syncGroups();

        }

        // bus.$emit('menuClickEvent', "loadAziende");

        setTimeout(

            function () {

                //console.log("Mounted app 2", refs.btn_add_tms);

            }, 50);

        //  document.getElementById('btnTimesheet').click() ;

        /*         console.log (  this.$route.path );
                if (  this.$route.path == '/' ) {
                    this.hideToolbars = true ;
                }
                else
                {
                    this.hideToolbars = false ;
                } */

    },
    name: 'App',

    components: {

        aziende,
        menubottom

    },

    watch: {
        /*  hideToolbars: function(){
             console.log ("passed hide");
         }, */
    },

    methods: {

        disableContextMenu(event) {
            event.preventDefault();
        },

        disableF12(event) {
            if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I')) {
                event.preventDefault();
            }
        },



        openPopupInfoUser: function () {

            $(".contpopupinfouser").show();

            this.$modal.show('popupInfoUser');

        },

        btnCancelInfoUser: function () {

            this.$modal.hide('popupInfoUser');

        },

        checkLogout: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");


            var response = await apiusers.checkLogoutNew(
                v_token,
                "2sign"
            ).then((res) => {


                console.log("res from checkLogoutNew", res);

                if (res.data.ForceLogout == 1) {

                    that.logout();

                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        registerLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");


            var response = await apiusers.registerLoginNew(
                v_token,
                "2sign"
            ).then((res) => {


                console.log("res from registerLoginNew", res);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        refreshMenu() {
            // Removing my-component from the DOM
            this.renderMenu = false;

            this.$nextTick(() => {
                // Adding the component back in
                this.renderMenu = true;
            });
        },

        setFieldActive(div) {

            $(div + " label").addClass("v-label--active");

        },

        setErrorField(div, msg_error) {

            var pointerVue = this;

            if (msg_error.length > 0) {

                pointerVue.addClassError(div);

                $(div + " .v-messages__wrapper").html(msg_error);

            }
            else {

                pointerVue.removeClassError(div);

                $(div + " .v-messages__wrapper").html("");

            }

        },

        initDivError(div, msg_error) {

            var pointerVue = this;

            // if (msg_error.length == 0) return false;

            $(div + " label").removeClass("v-label--active");

            window.msg_error = msg_error;

            $('body').on('blur', div, function (event) {

                console.log("EVENT: ", event);

                console.log("VALUE: ", $(div + " input").val());

                var valore = $(div + " input").val().replace("€", "").replace("$", "").trim();

                console.log("VALUE 2: ", valore);

                console.log("VALUE MSG ERROR: ", msg_error.length);

                if (msg_error.length > 0) {

                    if (valore == "") {

                        console.log("ADD CLASS");

                        pointerVue.addClassError(div);

                        $(div + " .v-messages__wrapper").html(msg_error);
                    } else {
                        console.log("REMOVE CLASS");

                        pointerVue.removeClassError(div);

                        $(div + " .v-messages__wrapper").html("");

                    }

                }
                else {

                    if (valore == "") {

                        $(div + " label").removeClass("v-label--active");

                    }

                }

            });

            $('body').on('focus', div, function (event) {

                console.log("EVENT: ", event);

                $(div + " label").addClass("v-label--active");

            });

        },

        addClassError(div) {

            //  alert("ok");

            // $(div +  " label").addClass("v-label--active error--text");
            $(div + " label").addClass("error--text");
            $(div + " label").removeClass("v-label--active");

            $(div).addClass("v-input v-input--has-state theme--light v-text-field v-text-field--is-booted error--text v-input--is-focused");

            $(div).removeClass("v-input--is-focused");

            $(div + " .v-messages").addClass("theme--light error--text");

        },

        removeClassError(div) {

            //  alert("ok");

            $(div + " label").removeClass("error--text");

            $(div).removeClass("v-text-field--is-booted error--text");

            $(div + " .v-messages").removeClass("error--text");

        },


        openmenu: async function () {

            //console.log ("visible", this.$refs.menubottom.sheetAziende);

            console.log("NAVBUTTONS: ", this.bnavbuttons);

            this.$refs.menubottom.sheetAziende = !this.$refs.menubottom.sheetAziende;

            console.log("MENU: ", this.old_bnavbuttons);

            if (this.$refs.menubottom.sheetAziende) // apro menu
            {

                this.old_bnavbuttons = this.bnavbuttons;

                this.bnavbuttons = null;

            } else {

                this.bnavbuttons = this.old_bnavbuttons;

            }

            //this.$refs.menubottom.openmenu();

        },
        syncGroups: async function () {

            /*         var token =     window.$cookies.get("token");
                    var system =    window.$cookies.get("system"); */
            var username = window.$cookies.get("username");
            //atob
            var response;
            var arrTimesheet = [];

            try {
                response = await this.$api
                    .getgroups(username)
                    .then((res) => {
                        arrTimesheet = res.data.InfoUser;
                        console.log("InfoUser", arrTimesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_gruppi = arrTimesheet.gruppi;
                        this.gruppi = info_gruppi;

                        console.log("info_gruppi", info_gruppi);

                        //  var arrGruppi = [];
                        //var arrDipendenti = [];
                        //var aziende = Object.entries(arrTimesheet.aziende);

                        /*   for (var gr of info_gruppi) {
                              console.log("az", az);
                              arrAziende.push(az[1].fld_name);
                          }
                          if (arrAziende.length > 1 && !this.setupNavBottomDone) {
                              this.aziende = arrAziende;
                              this.dipendenti = arrDipendenti;
                              this.globalArrAziende = aziende;

                          }
                          console.log("arraz", arrAziende);
                          console.log("globalArrAziende", this.globalArrAziende); */
                        // this.syncMOnthIcons();
                    });
            } catch (error) {
                console.log(error);
            }

            console.log(response);

        },

        syncMsg: async function () {

            var token = window.$cookies.get("token");
            //   var system = window.$cookies.get("system");

            var response;
            var arrTimesheet = [];

            //  var today = new Date();
            // var curMonth = today.getMonth() + 1;
            // var curYear = today.getFullYear();

            try {

                response = await this.$api
                    .getNumDocToSignApi(token, 0, 10)
                    .then((res) => {

                        //this.totalMsg = res.data.Messages.msg_tot_nr;

                        this.totalMsg = res.data.recordsTotal;

                        console.log("Timesheet", arrTimesheet);
                        // alert ( res.data.Messages.msg_tot_nr);

                    });

            } catch (error) {
                console.log(error);
            }

            console.log("response from getNumDocToSignApi", response);

        },

        syncMonth: async function () {
            this.syncMsg();

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            var response;
            var arrTimesheet = [];

            var today = new Date();
            var curMonth = today.getMonth() + 1;
            var curYear = today.getFullYear();

            try {
                response = await this.$api
                    .GetTimesheet(token, system, curYear, curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        //   console.log("Timesheet", arrTimesheet);

                        this.monthArray = arrTimesheet;
                        this.lista_orari = res.data.Timesheet.lista_orari;
                        //   console.log("this.lista_orari", this.lista_orari);

                        var result = Object.entries(arrTimesheet);
                        console.log(result * 0);

                        var info_month = arrTimesheet.info_month;

                        this.$root.$children[0].curDipTop = info_month.full_name;

                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            //  console.log("az", az);
                            arrAziende.push(az[1].fld_name);
                        }
                        if (arrAziende.length >= 1 && !this.setupNavBottomDone) {
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;

                        }
                        //  console.log("arraz", arrAziende);
                        //                        console.log("globalArrAziende", this.globalArrAziende);

                        // console.log("this.aziende", this.aziende);



                        this.syncMOnthIcons();
                    });
            } catch (error) {
                console.log(error);
            }

            /*  this.showSpinner = false; */
            console.log(response);
            /*  this.resetSumDay();
             this.updateSumTotals();
             this.setupNavTop();
             this.setupNavBottom(); */

        },

        menuclick: function (slug) {
            //alert( slug );
            if (slug == 'timesheet?dip=1') {
                this.menuAziende = true;

                bus.$emit('menuClickEvent', "showaziende");

            } else {
                this.menuAziende = false;
            }
        },

        changeMenu: function () {

            //console.log ("items",this.items);

            // this.items = [];

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            console.log(a, b);
            console.log("items", this.items);

            //Add menu for Admin
            /*  if (a == 'S' || a == 'Y' || b == 'S' || b == 'Y') {
                 this.items[2] = {
                     "id": 7,
                     "name": "Aziende",
                     "slug": "aziende",
                     "shown": 1,
                     "icon": "mdi-domain",
                     "children": [{
                         "icon": "mdi-account",
                         "id": 8,
                         "name": "Dipendenti",
                         "slug": "timesheet?dip=1",
                         "children": []
                     }]
                 };
             } else {
                 try {
                     //  delete (this.items[2]) ;
                     this.items.splice(2, 1);

                 } catch (error) {
                     console.log(error);
                 }
             } */

            //  alert (namesurname);
        },

        bottomIconsEvents(event) {
            // console.log (ist);
            var targetId = event.currentTarget.id;

            bus.$emit('bottomIconClickEvent', targetId);

        },
        setToolbar() {
            this.prova = false;
            // this.hideToolbars=true;
            //alert(1);
        },
        clickTimesheet() {
            //const elem = this.$refs.btnTimesheet;
            //elem.click()
            // console.log ( $event );
        },

        async profile() {
            /* if (this.totalMsg > 0 || this.totalMsg == '10+s')
                this.$router.push('/messages')
            else */
            this.$router.push('/profile')

        },

        async message() {

            this.$router.push('/documents')

        },


        logout: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.registerLogoutNew(
                v_token,
                "2sign"
            ).then((res) => {

                console.log("res from registerLogoutNew", res);

                window.$cookies.remove("a");
                window.$cookies.remove("b");
                window.$cookies.remove("token");
                window.$cookies.remove("system");
                window.$cookies.remove("token_system");
                window.$cookies.remove("username");
                window.$cookies.remove("user_id");
                window.$cookies.remove("is_ebadge");
                window.$cookies.remove("namesurname");

                localStorage.removeItem('content_signature');

                this.userLogged = false;
                this.$router.push('/')


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },


        async logoutOld() {
            /*  await this.$store.dispatch('LogOut') */
            // window.$cookies.remove("token"  );
            window.$cookies.remove("a");
            window.$cookies.remove("b");
            window.$cookies.remove("token");
            window.$cookies.remove("system");
            window.$cookies.remove("token_system");
            window.$cookies.remove("username");
            window.$cookies.remove("user_id");
            window.$cookies.remove("is_ebadge");
            window.$cookies.remove("namesurname");

            localStorage.removeItem('content_signature');

            this.userLogged = false;
            this.$router.push('/')
        }



    },

    computed: {

        msgFormatted: function () {
            if (this.totalMsg >= 10)
                return "10+";
            else
                return this.totalMsg;
        },
        showProva: {
            get() {
                return this.value
                // return true ;
            },
            set(value) {
                this.value = value
            }
        },
        hideToolbars() {
            //if (this.$route.path == '/' || this.$route.path == '/register') {
            if (this.$route.path == '/' || this.$route.path == '/register' || this.$route.path == '/discover') {
                return true;
            } else {
                return false;
            }
        },

    },

    data: () => ({

        fullNameUser: "",

        curDataOraLogin: "",

        demo_version: true,

        renderMenu: true,

        lista_orari: {},
        totalMsg: "",
        gruppi: [],
        aziende: [],
        dipendenti: [],
        globalArrAziende: {},

        curDipTop: "",
        curDipOreTop: "",
        curDipOreTopLabel: "",

        buttonsInfoUser: [{
            text: "",
            icon: "mdi-account",
            link: "",
            id: "btnInfoUserCodSign",
            disabled: false,
        }],

        clipped: false,

        showProgress: false,

        prova: false,
        drawer: false,
        item: 1,
        items: [{
            "id": 1,
            "name": "User",
            "slug": "user",
            "icon": "mdi-account-circle",
            "children": [

                {
                    "id": 3,
                    "name": "Modify account",
                    "slug": "profile",
                    "icon": "mdi-account-circle",
                    "children": []
                },
                {
                    "id": 4,
                    "name": "Change password",
                    "slug": "changepassword",
                    "icon": "mdi-account-circle",
                    "children": []
                }

            ]
        },

        {
            "id": 5,
            "name": "Timesheet",
            "slug": "timesheet",
            "shown": 1,
            "icon": "mdi-timetable",
            "children": [{
                "icon": "mdi-timetable",
                "id": 6,
                "name": "Edit timesheet",
                "slug": "timesheet",
                "children": []
            }]
        },

            /*     {
                  "id": 7,
                  "name": "Messages",
                  "slug": "messages",
                  "icon" :"mdi-account-circle" ,
                  "shown":1,
                  "children": [
                    {
                      "id": 8,
                      "name": "List messages",
                      "slug": "List-messages",
                      "children": []
                    },
                    {
                      "id": 9,
                      "name": "Old messages",
                      "slug": "Old-messages",
                      "children": []
                    }
                  ]
                } */

            /*  {
             "id": 15,
             "name": "Info",
             "slug": "Info",
             "shown":1
             }, */

        ],
        itemsOK: [{
            text: 'Timesheet',
            icon: 'mdi-timetable',
            link: '/timesheet'
        },
        {
            text: 'Profile',
            icon: 'mdi-account-circle',
            link: '/profile',
            items: [{
                title: 'Home',
                action: 'fa-barcode',
                to: '/dash',
                icon: 'mdi-account-circle',
            }]
        },
            /*  { text: 'Logout', icon: 'mdi-logout-variant', link: '/' } */
        ],

        old_bnavbuttons: null,

        bnavbuttons: null,
        itemss: [{
            action: 'fa-chart-line',
            title: 'Dashboard',
            to: '/timesheet',
            icon: 'mdi-account-circle',
        },
        {
            action: 'fa-user',
            title: 'Attractions',
            icon: 'mdi-account-circle',
            items: [{
                title: 'Home',
                action: 'fa-barcode',
                to: '/dash',
                icon: 'mdi-account-circle',
            }]
        },

        ]

        /*   hideToolbars: false , */
        //
    }),

    props: {
        /*  ok: String */
    }
};
</script>

<style>
.btnInfoUserCodSign {
    transform: translateX(25px);
    margin-left: 0px !important;
    padding-left: 0px !important;
}


.clRowInfoUser {
    display: flex;
    margin-left: 10px;
}


.curDipTopDocSign {
    color: black;
    font-size: 10px;
    display: flex;
    align-content: center;
    padding: 0px;
    line-height: 10px;
    margin-left: 30px;
}

.curDataOraLogin {
    color: black;
    margin-left: 10px;
    font-size: 8px;
}


ion-select {
    font-size: 10px;
}


.wrap_buttons_footer {}


.v-overflow {

    overflow-y: auto !important;
    padding-bottom: 115px !important;
    max-height: 100vh !important;

}

@media screen and (max-width: 768px) {

    .v-overflow {

        overflow-y: auto !important;
        padding-bottom: 175px !important;
        max-height: 100vh !important;

    }

    .wrap_buttons_footer {
        max-height: 60px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-width: 256px;
        overflow-x: auto;
        overflow-y: hidden;
    }


}


table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    top: 25%;
    background-color: #a4a4b0;
    /* test */

}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
    background-color: #000082;

}

.table-responsive {

    box-shadow: none;
    padding: 1px;
    min-height: 86vh;
}

.table td {
    padding: 3px 4px;

    vertical-align: middle;
}

.table thead th {
    padding: 3px;
}

#myTable_wrapper {
    max-height: 85vh;
    min-height: 300px;

    overflow-y: auto;
    overflow-x: hidden;
    /*  min-width: 90vw; */
    margin: 0 auto;

}

.inverted {
    filter: brightness(1) invert(1);
}

.btnTopDocSign {
    padding: 0px !important;
    min-width: 36px !important;
}

.curDipTop {
    color: black;
    font-size: 10px;
    display: flex;
    align-content: center;
    padding: 0px;
    line-height: 10px;
}

.curDipOreTop {
    color: black;
    font-size: 10px;
    display: flex;
    align-content: center;

    line-height: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-top: 4px;
    transform: translateX(20px);
    font-weight: bold;

}

.cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 142px;
    padding-left: 10px;
    justify-content: inherit;

}

#myTable_filter {
    background-image: inherit !important;
    background: #000082;

    border: 1px solid black;
    align-items: center;
    display: flex;
    justify-content: space-between;
    /*    min-height: 60px; */
    position: relative;

}

.dataTables_filter label {
    /*   position: absolute;
    right: 6px;
    display: block; */
    margin-right: 4px;
    margin-bottom: 0px;
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
    z-index: 99999;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white;
    border-radius: 50px;
}

#myTable_paginate {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    /*    transform: translateX(-20px); */
}

.paginate_button {
    padding: 12px;
}

.curDipOreTopLabel {
    color: black;
    font-size: 10px;
    display: flex;
    align-content: center;

    line-height: 10px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-top: 4px;
    transform: translateX(-10px);
    font-weight: bold;
}

.prova {
    color: black;
    background: red;
    font-size: 15px;
    z-index: 111111;
    text-align: center;
}

.v-toolbar__content,
.v-toolbar__extension {
    background: #000082;
    background: white;
}

.primary .v-btn>.v-btn__content .v-icon {
    color: black;
}

.v-toolbar__content a {
    color: white;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-height: 56px;
    height: 56px;

}

.theme--dark.v-btn.v-btn--has-bg {
    background-color: inherit !important;
}

@media screen and (min-width: 768px) {

    #myTable_wrapper {
        /*  min-width: 700px; */
    }
}

@media screen and (max-width: 768px) {

    table.dataTable {
        /*  min-width: 98vw; */
    }

    .card,
    .card-body {
        padding: 0px;
    }

    #myTable_wrapper {
        /*min-width: 700px;*/
        box-sizing: border-box;
        /*  min-width: 100vw; */
        overflow-x: auto;
        /*  max-width: 90vw; */
        margin: 0 auto;

    }

}

#myTable {

    overflow-x: auto;

}

input[type=search] {

    margin-left: 0px;
    min-width: 90px !important;
    max-width: 160px;
}

@media only screen and (max-width: 600px) {

    /*   .contListBookingRsa tbody {
        padding-top: 24px;
    } */

    #myTable {
        /*  margin-top: 72px; */
        /* transform: translateX(-12px); */

    }

    #myTable_filter {
        border-radius: 0px !important;
        position: fixed;
        width: 100%;
        left: 0px;
        z-index: 2;

    }

    .emt__footer {
        bottom: -1px;
    }

    /* For main Grid */
    .contListBookingRsa .table-responsive {
        /*  min-width: 91vw; */
        margin: 0 auto;

        padding: 0;
        position: fixed;
        left: 0px;
    }

    .cont_tickets {
        /*  left: 0;
        right: 0;
        position: absolute;
        top: 79px; */
    }

}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
$font-family: 'Montserrat',
    serif;

.my-application * {
    font-family: $font-family, sans-serif !important;
}

.my-application {

    .headline,
    [class*='display-'],
    [class*='text-'] {
        /* color: #36405a; */
        font-family: $font-family, sans-serif !important;
    }

    font-family: $font-family,
    sans-serif !important;
    font-size:10px;
}

:root {
    --ion-color-primary: #000082;
    --ion-font-family: "Montserrat", serif, sans-serif !important;
    --ion-font-size: 8px;
}


.v-navigation-drawer__content .v-list-item__title {
    font-size: 11px !important;
}

.v-label {
    font-size: 0.9em !important;
}

.v-input {
    font-size: 0.9em !important;
    padding: 10px;
}

.headerTop {}

.v-badge__wrapper {
    right: auto;
    /* z-index: 1; */
    zoom: 1;
    transform: translateX(3px);
}

.v-badge__badge {
    color: black !important;
}

.imgProfile {
    /*   transform: translate(-6px,-2px); */
}

.btnProfile {
    /* margin-right: 4px; */
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    display: none;
}


.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}


.bottomBtnMenu {
    position: fixed !important;
    right: 0px;
}

.v-dialog {
    transition: .05s cubic-bezier(.25, .8, .25, 1);
}

#myTable_filter .datetime-text {
    /*    max-width: 10px; */
    margin-left: 10px !important;
}

.cont-table {
    font-size: 10px;
    padding-top: 14px;
    margin: 0 auto;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {

    background-color: #a4a4b0;
}

td,
th {
    text-align: left;
}

#myTable_filter .datetime-text {
    font-size: 10px;
}

.card-body {
    background-color: #fff3;
}

tbody {
    background: #ffffff7a;
}

.paginate_button.disabled {
    visibility: hidden;
}

.active-row {
    background: transparent !important;
    border: 1px solid #00000087 !important;
}

#dataTables_filter label {
    padding: 0px;
}

#myTable_filter {
    min-height: 54px;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
    box-shadow: none !important;
}
</style>
